import React from 'react'
import tw from 'twin.macro'
import parse from 'html-react-parser'
import { useInView } from 'react-intersection-observer'

const Section = tw.section`relative`
const VideoWrap = tw.div`relative px-offset-xs lg:px-offset-lg max-w-screen-xl mx-auto`
const IframeWrap = tw.div`relative`
const ImageHeadlineWrap = tw.div`relative mx-offset-xs lg:mx-offset-lg`
const Headline = tw.p`w-full md:w-9/12 lg:w-8/12 `

const Background = tw.div`absolute h-3/4 bg-superLight w-9/12 lg:w-8/12 top-0 left-0 -translate-y-4 sm:-translate-y-20`

const getVideoId = (url) => {
	url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0] // eslint-disable-line
}
const WpAcfVideoBlock = ({ data }) => {
	const { anchor } = data.attributes
	const module = data.modulesVideo
	const videoId = module.videoEmbed && getVideoId(module.videoEmbed)

	const [ref, isVisible] = useInView({ threshold: 1, triggerOnce: true })

	if (!module.videoEmbed && !module.videoFile?.localFile?.publicURL) return ''
	return (
		<Section {...(anchor !== '' ? { id: anchor } : {})} className='emf-pb-spacing' ref={ref}>
			{module.showBackground && <Background />}
			<ImageHeadlineWrap>{module.headline && <Headline className='mb-offset-xs lg:mb-offset-lg headline-h2'>{parse(module.headline)}</Headline>}</ImageHeadlineWrap>
			<VideoWrap>
				{isVisible && (
					<IframeWrap className=' aspect-w-16 aspect-h-9'>
						{videoId && module.videoType === 'embed' && (
							<iframe title={`EMF Racing ${videoId}`} src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`} frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen></iframe>
						)}
						{module.videoFile?.localFile?.publicURL && module.videoType === 'file' && (
							<video controls autoPlay muted playsInline src={module.videoFile?.localFile?.publicURL} className='w-full'></video>
						)}
					</IframeWrap>
				)}
			</VideoWrap>
		</Section>
	)
}
export default WpAcfVideoBlock
